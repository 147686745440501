import React from 'react'
import { DropdownButton,Form, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'

function CommunicationHub() {
  return (
    <>
        <Header/>
    <section className="tsd__details" style={{backgroundImage:"url(./assets/img/inner-banner.jpg)"}}>
        <div className="container">
            <div className="page__title">
                <div className="icon">
                    <img src="./assets/img/icons/communication.svg" alt="" />
                </div>
                <h2>محور الاتصالات</h2>
            </div>
        </div>
      </section>
      <section className="communication__hub">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="communication border-0 pl-0">
        <div className="discussion">
          <h4>قمة مناقشات</h4>
          <DropdownButton id="dropdown-item-button" title="المواضيع الأخيرة">
              <Dropdown.Item as="button">المواضيع الأخيرة</Dropdown.Item>
              <Dropdown.Item as="button">المواضيع الأخيرة</Dropdown.Item>
              <Dropdown.Item as="button">المواضيع الأخيرة</Dropdown.Item>
            </DropdownButton>
        </div>

        <div className="communication__form">
          <div className="card__form">
          <div className="form-group">
            <Form.Control type="email" placeholder="اكتب العنوان" />
          </div>
          <div className="form-group">
              <Form.Control
                as="textarea"
                placeholder="أكتب شيئا"
                style={{ height: '100px' }}
              />
          </div>
          <div className="form-group submit__btn">
          <div className="upload-btn-wrapper">
            <button className="btn btn-upload"><img src="assets/img/icons/upload.svg" alt="" />الصور</button>
            <input type="file" name="myfile" />
          </div>
          <button type="submit" className="btn btn-primary btn-upload-submit">أرسلت</button>
          </div>
          </div>
        </div>
        <div className="comments__grid">
          <div className="header">
          <div className="avatar">
            {/* <img src="assets/img/icons/user.svg" alt="" /> */}
            <h4>لوي روبن</h4>
          </div>
          <div className="time">
            <h4>1 دقيقة مضت</h4>
          </div>
          </div>
          <div className="comment">
            <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.</p>
          </div>
          <div className="comment__count">
            <p>14 تعليق</p>
          </div>
        </div>
        <div className="comments__grid">
          <div className="header">
          <div className="avatar">
            <h4>جين دو</h4>
          </div>
          <div className="time">
            <h4>1 دقيقة مضت</h4>
          </div>
          </div>
          <div className="comment">
            <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.</p>
          </div>
          <div className="comment__count">
            <p>14 تعليق</p>
          </div>
        </div>
        <div className="comments__grid">
          <div className="header">
          <div className="avatar">
            <h4>جين دو</h4>
          </div>
          <div className="time">
            <h4>1 دقيقة مضت</h4>
          </div>
          </div>
          <div className="comment">
            <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.</p>
            <img src="assets/img/comment.jpg" alt="" />
          </div>
          <div className="comment__count">
            <p>14 تعليق</p>
          </div>
        </div>
        <div className="comments__grid">
          <div className="header">
          <div className="avatar">
            <h4>جين دو</h4>
          </div>
          <div className="time">
            <h4>1 دقيقة مضت</h4>
          </div>
          </div>
          <div className="comment">
            <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.</p>
          </div>
          <div className="comment__count">
            <p>14 تعليق</p>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div className="text-center mt-5"><Link to={'/'} className="btn btn-primary">عرض كافة</Link></div>
  </div>
</section>
      <Footer/>

    </>
  )
}

export default CommunicationHub