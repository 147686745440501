
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'

function Initiatives() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                // backdrop="static"
                keyboard={false}
                dialogClassName="modal-70w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
                className='tarainingModal initiativeModal'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="initiative__title">
                            <h3>Maintenance of lorem Ipsum</h3>
                            <p>May 30, 2022 at 1:11 pm</p>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p> Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar.</p>
                    <div className="tagged__names">
                        <div className="names">
                            <div className="list">
                                <h4>@JOHN DOE</h4>
                            </div>
                            <div className="list">
                                <h4>@JOHN DOE</h4>
                            </div>
                            <div className="list">
                                <h4>@JOHN DOE</h4>
                            </div>
                            <div className="list">
                                <h4>@JOHN DOE</h4>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Header />
            <section className="tsd__details" style={{ backgroundImage: "url(./assets/img/inner-banner.jpg)" }}>
                <div className="container">
                    <div className="page__title">
                        <div className="icon">
                            <img src="./assets/img/icons/initiatives.svg" alt="" />
                        </div>
                        <h2>Initiatives</h2>
                    </div>
                </div>
            </section>
            <section className="section__initiatives">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-5">
                            <div className="initiatives__section">
                                <div className="initiativesGrid">
                                    <h6>May 30, 2022 at 1:11 pm</h6>
                                    <div className="title">
                                        <h2>Maintenance of lorem Ipsum</h2>
                                        <div className="names">
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>Lorem ipsum dolor sit amet orem ipsum dolor sit Lorem ipsum dolor sit amet</p>
                                        <button className='btn-sm btn-primary' onClick={handleShow}>View Details</button>
                                    </div>
                                </div>
                                <div className="initiativesGrid">
                                    <h6>May 30, 2022 at 1:11 pm</h6>
                                    <div className="title">
                                        <h2>Maintenance of lorem Ipsum</h2>
                                        <div className="names">
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>Lorem ipsum dolor sit amet orem ipsum dolor sit Lorem ipsum dolor sit amet</p>
                                        <button className='btn-sm btn-primary' onClick={handleShow}>View Details</button>
                                    </div>
                                </div>
                                <div className="initiativesGrid">
                                    <h6>May 30, 2022 at 1:11 pm</h6>
                                    <div className="title">
                                        <h2>Maintenance of lorem Ipsum</h2>
                                        <div className="names">
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>Lorem ipsum dolor sit amet orem ipsum dolor sit Lorem ipsum dolor sit amet</p>
                                        <button className='btn-sm btn-primary' onClick={handleShow}>View Details</button>
                                    </div>
                                </div>
                                <div className="initiativesGrid">
                                    <h6>May 30, 2022 at 1:11 pm</h6>
                                    <div className="title">
                                        <h2>Maintenance of lorem Ipsum</h2>
                                        <div className="names">
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>Lorem ipsum dolor sit amet orem ipsum dolor sit Lorem ipsum dolor sit amet</p>
                                        <button className='btn-sm btn-primary' onClick={handleShow}>View Details</button>
                                    </div>
                                </div>
                                <div className="initiativesGrid">
                                    <h6>May 30, 2022 at 1:11 pm</h6>
                                    <div className="title">
                                        <h2>Maintenance of lorem Ipsum</h2>
                                        <div className="names">
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>Lorem ipsum dolor sit amet orem ipsum dolor sit Lorem ipsum dolor sit amet</p>
                                        <button className='btn-sm btn-primary' onClick={handleShow}>View Details</button>
                                    </div>
                                </div>
                                <div className="initiativesGrid">
                                    <h6>May 30, 2022 at 1:11 pm</h6>
                                    <div className="title">
                                        <h2>Maintenance of lorem Ipsum</h2>
                                        <div className="names">
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>Lorem ipsum dolor sit amet orem ipsum dolor sit Lorem ipsum dolor sit amet</p>
                                        <button className='btn-sm btn-primary' onClick={handleShow}>View Details</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="initiatives__section">
                                <div className="initiativesGrid">
                                    <h6>May 30, 2022 at 1:11 pm</h6>
                                    <div className="title">
                                        <h2>Maintenance of lorem Ipsum</h2>
                                        <div className="names">
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>Lorem ipsum dolor sit amet orem ipsum dolor sit Lorem ipsum dolor sit amet</p>
                                        <button className='btn-sm btn-primary' onClick={handleShow}>View Details</button>
                                    </div>
                                </div>
                                <div className="initiativesGrid">
                                    <h6>May 30, 2022 at 1:11 pm</h6>
                                    <div className="title">
                                        <h2>Maintenance of lorem Ipsum</h2>
                                        <div className="names">
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>Lorem ipsum dolor sit amet orem ipsum dolor sit Lorem ipsum dolor sit amet</p>
                                        <button className='btn-sm btn-primary' onClick={handleShow}>View Details</button>
                                    </div>
                                </div>
                                <div className="initiativesGrid">
                                    <h6>May 30, 2022 at 1:11 pm</h6>
                                    <div className="title">
                                        <h2>Maintenance of lorem Ipsum</h2>
                                        <div className="names">
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>Lorem ipsum dolor sit amet orem ipsum dolor sit Lorem ipsum dolor sit amet</p>
                                        <button className='btn-sm btn-primary' onClick={handleShow}>View Details</button>
                                    </div>
                                </div>
                                <div className="initiativesGrid">
                                    <h6>May 30, 2022 at 1:11 pm</h6>
                                    <div className="title">
                                        <h2>Maintenance of lorem Ipsum</h2>
                                        <div className="names">
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>Lorem ipsum dolor sit amet orem ipsum dolor sit Lorem ipsum dolor sit amet</p>
                                        <button className='btn-sm btn-primary' onClick={handleShow}>View Details</button>
                                    </div>
                                </div>
                                <div className="initiativesGrid">
                                    <h6>May 30, 2022 at 1:11 pm</h6>
                                    <div className="title">
                                        <h2>Maintenance of lorem Ipsum</h2>
                                        <div className="names">
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>Lorem ipsum dolor sit amet orem ipsum dolor sit Lorem ipsum dolor sit amet</p>
                                        <button className='btn-sm btn-primary' onClick={handleShow}>View Details</button>
                                    </div>
                                </div>
                                <div className="initiativesGrid">
                                    <h6>May 30, 2022 at 1:11 pm</h6>
                                    <div className="title">
                                        <h2>Maintenance of lorem Ipsum</h2>
                                        <div className="names">
                                            <div className="list"><h4>@JOHN DOE</h4></div>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>Lorem ipsum dolor sit amet orem ipsum dolor sit Lorem ipsum dolor sit amet</p>
                                        <button className='btn-sm btn-primary' onClick={handleShow}>View Details</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Initiatives