import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import "../Styles/Calendar-detailed.css"
import { useEffect,useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list';
import '../Styles/Calendar.css'
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


import events from "../components/events";

function CalendarDetails() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [adddata, setEvents] = useState([]);

  const [search, setSearch] = useState(false)
  const searchShow=()=>{
    setSearch(!search)
  }
  return (
    <>
      <Header/>
      <section className="tsd__details" style={{backgroundImage:"url(./assets/img/inner-banner.jpg)"}}>
        <div className="container">
            <div className="page__title">
                <div className="icon">
                    <img src="./assets/img/icons/calendar.svg" alt="" />
                </div>
                <h2>تقويم</h2>
            </div>
        </div>
      </section>
      <section className="detailed__calendar">
        <div className="container position-relative">
          {
            search ==true &&         <div className="search__btn">
            <div className="input-group">
                <div className="form-outline">
                  <input type="search" placeholder='ابحث هنا' className="form-control" />
                </div>
                <button type="button" className="btn btn-primary">
                <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
          }

            <div className="row justify-content-center">
                <div className="col-md-10">
                <FullCalendar
        defaultView="dayGridMonth"
        headerToolbar={{
          right: "addEvent prev,next search",
          left: "title",
          center: ""
        }}
        themeSystem="Simplex"
        plugins={[dayGridPlugin]}
        events={events}
        customButtons={{
          addEvent: {
            icon: `bi bi-plus-square-fill`,
            click: handleShow
          },
          search: {
            icon: `bi bi-search`,
            click: searchShow
          }
         
        }}
        locale="ar"
        
      />
                </div>
            </div>
        </div>
      </section>
      <section className="upcoming__events bg-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={6} className='col-tab-12'>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="first">
                <div className="tab__grid">
                  <div className="title">
                    <h2>لقاء في جو</h2>
                    <p>10 يوليو 2022</p>
                  </div>
                  <div className="left__btn">
                    <button className='btn-time'>18:00</button>
                    <button className='btn-arrow'><img src="./assets/img/icons/arrow.svg" alt="" /></button>
                  </div>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
              <div className="tab__grid">
                  <div className="title">
                    <h2>اجتماع مع الفريق</h2>
                    <p>10 يوليو 2022</p>
                  </div>
                  <div className="left__btn">
                    <button className='btn-time'>18:00</button>
                    <button className='btn-arrow'><img src="./assets/img/icons/arrow.svg" alt="" /></button>
                  </div>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third">
              <div className="tab__grid">
                  <div className="title">
                    <h2>اجتماع في جو</h2>
                    <p>10 يوليو 2022</p>
                  </div>
                  <div className="left__btn">
                    <button className='btn-time'>18:00</button>
                    <button className='btn-arrow'><img src="./assets/img/icons/arrow.svg" alt="" /></button>
                  </div>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fourth">
              <div className="tab__grid">
                  <div className="title">
                    <h2>اجتماع مع الفريق</h2>
                    <p>10 يوليو 2022</p>
                  </div>
                  <div className="left__btn">
                    <button className='btn-time'>18:00</button>
                    <button className='btn-arrow'><img src="./assets/img/icons/arrow.svg" alt="" /></button>
                  </div>
                </div>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={6} className='col-tab-12'>
          <Tab.Content>
            <Tab.Pane eventKey="first">
             <div className="tab__content">
              <div className="header">
                <img src="./assets/img/icons/alarm.svg" alt="" />
                <div className="date__time">
                  <p>10 يوليو 2022</p>
                  <p>15:00 مساءً</p>
                </div>
              </div>
              <div className="decription">
                <h4>لقاء في جو</h4>
                <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصليلوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي</p>
              </div>
             </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
            <div className="tab__content">
              <div className="header">
                <img src="./assets/img/icons/alarm.svg" alt="" />
                <div className="date__time">
                  <p>10 يوليو 2022</p>
                  <p>15:00 مساءً</p>
                </div>
              </div>
              <div className="decription">
                <h4>لقاء في جو</h4>
                <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصليلوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي</p>
              </div>
             </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
            <div className="tab__content">
              <div className="header">
                <img src="./assets/img/icons/alarm.svg" alt="" />
                <div className="date__time">
                  <p>10 يوليو 2022</p>
                  <p>15:00 مساءً</p>
                </div>
              </div>
              <div className="decription">
                <h4>لقاء في جو</h4>
                <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصليلوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي</p>
              </div>
             </div>
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">
            <div className="tab__content">
              <div className="header">
                <img src="./assets/img/icons/alarm.svg" alt="" />
                <div className="date__time">
                  <p>10 يوليو 2022</p>
                  <p>15:00 مساءً</p>
                </div>
              </div>
              <div className="decription">
                <h4>لقاء في جو</h4>
                <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصليلوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي</p>
              </div>
             </div>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose} size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton>
          <Modal.Title>إضافة حدث</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="row">
              <div className="col-md-4">
              <div className="form-group">
              <input type="text" className="form-control" placeholder="اسم الحدث" />
            </div>
              </div>
              <div className="col-md-4">
              <div className="form-group">
              <input type="time" className="form-control" placeholder="اسم الحدث" />
            </div>
              </div>
              <div className="col-md-4">
              <div className="form-group">
              <input type="date" className="form-control" placeholder="اسم الحدث" />
            </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
          إرسال
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer/>
    </>
  )
}

export default CalendarDetails