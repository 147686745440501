
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'

function Activities() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                // backdrop="static"
                keyboard={false}
                dialogClassName="modal-70w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
                className='tarainingModal'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Maintenance of lorem Ipsum</Modal.Title>
                </Modal.Header>
                <Modal.Body>
             Egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar.
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-common'>Join</button>
                </Modal.Footer>
            </Modal>
            <Header />
            <section className="tsd__details" style={{ backgroundImage: "url(./assets/img/inner-banner.jpg)" }}>
                <div className="container">
                    <div className="page__title">
                        <div className="icon">
                            <img src="./assets/img/icons/activities.svg" alt="" />
                        </div>
                        <h2>Activities</h2>
                    </div>
                </div>
            </section>
            <section className="news_poll poll__lists surveyDetails">
                <div className="container training__suggestions">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="trainingGrids" data-aos="fade-up">
                                <div className="title">
                                    <h3>Lorem ipsum dolor sit amet</h3>
                                    <p>May 30, 2022</p>
                                </div>
                                <div className="description">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar.</p>
                                    <div className="text-left"><button className='btn-sm btn-primary' onClick={handleShow}>JOIN</button></div>
                                </div>
                            </div>
                            <hr />
                            <div className="trainingGrids" data-aos="fade-up">
                                <div className="title">
                                    <h3>Lorem ipsum dolor sit amet</h3>
                                    <p>May 30, 2022</p>
                                </div>
                                <div className="description">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar..</p>
                                    <div className="text-left"><button className='btn-sm btn-primary' onClick={handleShow}>JOIN</button></div>
                                </div>
                            </div>
                            <div className="trainingGrids" data-aos="fade-up">
                                <div className="title">
                                    <h3>Lorem ipsum dolor sit amet</h3>
                                    <p>May 30, 2022</p>
                                </div>
                                <div className="description">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar.</p>
                                    <div className="text-left"><button className='btn-sm btn-primary' onClick={handleShow}>JOIN</button></div>
                                </div>
                            </div>
                            <hr />
                            <div className="trainingGrids" data-aos="fade-up">
                                <div className="title">
                                    <h3>Lorem ipsum dolor sit amet</h3>
                                    <p>May 30, 2022</p>
                                </div>
                                <div className="description">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar..</p>
                                    <div className="text-left"><button className='btn-sm btn-primary' onClick={handleShow}>JOIN</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Activities