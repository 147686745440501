import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import ScrollToTop from './components/ScrollTop';
import About from './pages/About';
import Activities from './pages/Activities';
import AnnouncementDetail from './pages/AnnouncementDetail';
import AnnouncementLists from './pages/AnnouncementLists';
import Benefits from './pages/Benefits';
import CalendarDetails from './pages/CalendarDetails';
import CommunicationHub from './pages/CommunicationHub';
import EventDetail from './pages/EventDetail';
import Events from './pages/Events';
import Gallery from './pages/Gallery';
import Home from './pages/Home';
import Initiatives from './pages/Initiatives';
import Leaderboard from './pages/Leaderboard';
import NewsDetails from './pages/NewsDetsils';
import NewsLists from './pages/NewsLists';
import PollLists from './pages/PollLists';
import Rules from './pages/Rules';
import Training from './pages/Training';
import VaccancyLists from './pages/VaccancyLists';
import './Styles/Responsive.css';

function App() {
  return (
    <>
     <ScrollToTop/>
    <Routes>
        <Route index element={<Home/>} />
        <Route path='/calendar' element={<CalendarDetails/>} />
        <Route path='/newsLists' element={<NewsLists/>} />
        <Route path='/newsDetails' element={<NewsDetails/>} />
        <Route path='/pollLists' element={<PollLists/>} />
        <Route path='/announcements' element={<AnnouncementLists/>} />
        <Route path='/announcementsDetail' element={<AnnouncementDetail/>} />
        <Route path='/events' element={<Events/>} />
        <Route path='/eventDetail' element={<EventDetail/>} />
        <Route path='/vaccancy' element={<VaccancyLists/>} />
        <Route path='/leaderboard' element={<Leaderboard/>} />
        <Route path='/communication' element={<CommunicationHub/>} />
        <Route path='/gallery' element={<Gallery/>} />
        <Route path='/training' element={<Training/>} />
        <Route path='/initiatives' element={<Initiatives/>} />
        <Route path='/benefits' element={<Benefits/>} />
        <Route path='/activities' element={<Activities/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/rules' element={<Rules/>} />
    </Routes>
    </>

  );
}

export default App;
