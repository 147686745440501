import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import '../Styles/Gallery.css'
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";



const imageLists = [
    "./assets/img/gallery/1.jpg",
    "./assets/img/gallery/2.jpg",
    "./assets/img/gallery/3.jpg",
    "./assets/img/gallery/4.jpg",
    "./assets/img/gallery/5.jpg",
    "./assets/img/gallery/6.jpg",
    "./assets/img/gallery/1.jpg",
    "./assets/img/gallery/2.jpg",
    "./assets/img/gallery/3.jpg",
    "./assets/img/gallery/4.jpg",
    "./assets/img/gallery/5.jpg",
    "./assets/img/gallery/6.jpg",
  ];
  

function Gallery() {
  return (
    <>
    <Header/>
    <section className="tsd__details" style={{backgroundImage:"url(./assets/img/inner-banner.jpg)"}}>
        <div className="container">
            <div className="page__title">
                <div className="icon">
                    <img src="./assets/img/icons/gallery.svg" style={{height:'42px'}} alt="" />
                </div>
                <h2>صالة عرض</h2>
            </div>
        </div>
      </section>
      <section className="image__gallery gallery__listing">
  <div className="container">
    <div className="row gallery__row">
    <LightgalleryProvider>
        {imageLists.map((image, i) => (
         <div className='col-md-3 col-tab-4' key={i}>
             <div className="gallery__image">
                <div className="overlay"></div>
             <LightgalleryItem src={image}>
               <img src={image} className='w-100 image' />
               <div className="date">
                 <p>07 Mar</p>
               </div>
               <div class="overlay">
               <div className="hover">
                 <span>يوليو 2022</span>
                 <p>استبدل هذا النص استبدل هذا النص</p>
               </div>
               </div>

           </LightgalleryItem>
             </div>
         </div>
        ))}
    </LightgalleryProvider>
    
    </div>
    <div className="text-center mt-4">
        <Link to={'/'} className="btn btn-primary">عرض كافة</Link>
    </div>

  </div>
</section>


    <Footer/>
    </>
  )
}

export default Gallery