
import React from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AddComments from '../components/AddComments'
import Footer from '../components/Footer'
import Header from '../components/Header'

function Events() {
  return (
    <>
    <Header/>
    <section className="tsd__details" style={{backgroundImage:"url(./assets/img/inner-banner.jpg)"}}>
        <div className="container">
            <div className="page__title">
                <div className="icon">
                    <img src="./assets/img/icons/event.svg" alt="" />
                </div>
                <h2>الفعاليات</h2>
            </div>
        </div>
      </section>
      <section className="events__announcements mt-5 default-bg event__listing">
  <div className="container">
    <div className="row">
    <div className="default__heading justify-content-end">
            <DropdownButton id="dropdown-item-button" title="الموارد البشرية">
              <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
              <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
              <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
            </DropdownButton>
        </div>
      <div className="col-md-6">
      <div className="events__announcements__grid">
                <div className="card boxshadow-0 pl-0" data-aos="fade-up" data-aos-delay="100">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-tab-12">
                      <div className="event__image">
                        <img src="assets/img/announcements/1.jpg" className="img-fluid" alt="..." />
                      </div>
                    </div>
                    <div className="col-md-9 col-tab-12">
                      <div className="card-body event">
                         <Link to='/eventDetail'><h2 className='pt-0 pb-2'>Lorem ipsum dolor sit amet</h2></Link>
                        <div className="news__announcement-content">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card boxshadow-0 pl-0 pt-0" data-aos="fade-up" data-aos-delay="200">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-tab-12">
                      <div className="event__image">
                        <img src="assets/img/announcements/2.jpg" className="img-fluid" alt="..." />
                      </div>
                    </div>
                    <div className="col-md-9 col-tab-12">
                      <div className="card-body event">
                         <Link to='/eventDetail'><h2 className='pt-0 pb-2'>Lorem ipsum dolor sit amet</h2></Link>
                        <div className="news__announcement-content">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card boxshadow-0 pl-0 pt-0" data-aos="fade-up" data-aos-delay="300">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-tab-12">
                      <div className="event__image">
                        <img src="assets/img/announcements/3.jpg" className="img-fluid" alt="..." />
                      </div>
                    </div>
                    <div className="col-md-9 col-tab-12">
                      <div className="card-body event">
                        <Link to='/eventDetail'><h2 className='pt-0 pb-2'>Lorem ipsum dolor sit amet</h2></Link>
                        <div className="news__announcement-content">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        </div>
      </div>
      <div className="col-md-6">
      <div className="events__announcements__grid">
                <div className="card boxshadow-0 pl-0" data-aos="fade-up" data-aos-delay="100">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-tab-12">
                      <div className="event__image">
                        <img src="assets/img/announcements/1.jpg" className="img-fluid" alt="..." />
                      </div>
                    </div>
                    <div className="col-md-9 col-tab-12">
                      <div className="card-body event">
                         <Link to='/eventDetail'><h2 className='pt-0 pb-2'>Lorem ipsum dolor sit amet</h2></Link>
                        <div className="news__announcement-content">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card boxshadow-0 pl-0 pt-0" data-aos="fade-up" data-aos-delay="200">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-tab-12">
                      <div className="event__image">
                        <img src="assets/img/announcements/2.jpg" className="img-fluid" alt="..." />
                      </div>
                    </div>
                    <div className="col-md-9 col-tab-12">
                      <div className="card-body event">
                         <Link to='/eventDetail'><h2 className='pt-0 pb-2'>Lorem ipsum dolor sit amet</h2></Link>
                        <div className="news__announcement-content">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card boxshadow-0 pl-0 pt-0" data-aos="fade-up" data-aos-delay="300">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-tab-12">
                      <div className="event__image">
                        <img src="assets/img/announcements/3.jpg" className="img-fluid" alt="..." />
                      </div>
                    </div>
                    <div className="col-md-9 col-tab-12">
                      <div className="card-body event">
                        <Link to='/eventDetail'><h2 className='pt-0 pb-2'>Lorem ipsum dolor sit amet</h2></Link>
                        <div className="news__announcement-content">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        </div>
      </div>
    </div>
    <div className="text-center mt-5"><Link to={'/'} className="btn btn-primary">عرض كافة</Link></div>
  </div>

</section>
<section className="bg-whte">
    <div className="container">
    <AddComments/>
    </div>
</section>
    <Footer/>
    </>
  )
}

export default Events