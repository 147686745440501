import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
    <section className="footer pb-0">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="footer__logo">
                        <img src="assets/img/logo.svg" alt="" />
                    </div>
                </div>
                <div className="col-md-6">
                   <div className="footer__links">
                   <div className="footer__widgets">
                        <ul>
                            <li>
                                <Link to={''}>الصفحة الرئيسية</Link>
                            </li>
                            <li>
                                <Link to={''}>أحدث الأخبار</Link>
                            </li>
                            <li>
                                <Link to={''}>الفعاليات</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__widgets">
                        <ul>
                            <li>
                                <Link to={''}>الدراسة الاستكشافية</Link>
                            </li>
                            <li>
                                <Link to={''}>تنبيه</Link>
                            </li>
                            <li>
                                <Link to={''}>التصويت</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__widgets">
                        <ul>
                            <li>
                                <Link to={''}>إعلان</Link>
                            </li>
                            <li>
                                <Link to={''}>وظيفي </Link>
                            </li>
                            <li>
                                <Link to={''}>دردشة</Link>
                            </li>
                        </ul>
                    </div>
                   </div>
                </div>
            </div>
        </div>
        <div className="copyright">
            <div className="container">
                <p>© Dubai Police 2022</p>
            </div>
        </div>
    </section>

    </>
  )
}

export default Footer