
import React from 'react';
import Footer from '../components/Footer'
import Header from '../components/Header'

function Rules() {
    return (
        <>
          
            <Header />
            <section className="tsd__details" style={{ backgroundImage: "url(./assets/img/inner-banner.jpg)" }}>
                <div className="container">
                    <div className="page__title">
                        <div className="icon">
                            <img src="./assets/img/icons/rules.svg" alt="" />
                        </div>
                        <h2>Rules</h2>
                    </div>
                </div>
            </section>
            <section className="tsd__about__rules">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                        <div className="contents">
                            <h2>المضي الشطر بالفشل أي كلّ. دأبوا الوزراء فقد بل, إختار وحرمان الشهيرة بها أم. بحق مسؤولية</h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. </p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, veritatis? Corporis nulla voluptatem, beatae dolore cupiditate fugiat odio eaque dignissimos qui laboriosam repellendus porro nesciunt ut suscipit cum accusamus a.</p>
                            <h4>Where can I get some?</h4>
                            <ul>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</li>
                                <li>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</li>
                                <li>Contrary to popular belief, Lorem Ipsum is not simply</li>
                                <li>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC</li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Rules