import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from '../components/Header'
import Marquee from "react-fast-marquee";
import { Link } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';


import { Form } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Footer from '../components/Footer';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import events from "../components/events";
import Piechart from '../components/Piechart';
import Chart from '../components/Chart';


function Home() {
  let banner = {
    dots: false,
    // infinite: true,
    // lazyLoad: true,
    speed: 500,
    slidesPerRow: 4,
    nextArrow: <img className='arrow' src='assets/img/icons/arrowleft-circle.svg' data-aos="fade-up" />,
    prevArrow: <img className='arrow' src='assets/img/icons/arrowright-circle.svg' data-aos="fade-up" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesPerRow: 3,
          dots: false,
        }
      },
      {
        breakpoint: 1300,
        settings: {
          slidesPerRow: 3,
          dots: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesPerRow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesPerRow: 1,
        }
      }
    ]
  };
  let settings = {
    dots: false,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 3,
    slidesPerRow: 3,
    nextArrow: <img src='assets/img/icons/arrow-left.svg' />,
    prevArrow: <img src='assets/img/icons/arrow-right.svg' />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  let news = {
    dots: false,
    rtl: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: <img src='assets/img/icons/arrow-left.svg' />,
    prevArrow: <img src='assets/img/icons/arrow-right.svg' />,
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 1,
          dots: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  const [show, setShow] = useState(false);
  const [advanced, setAdvanced] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [adddata, setEvents] = useState([]);

  const [search, setSearch] = useState(false)
  const searchShow = () => {
    setSearch(!search)
  }

  const advancedSearch = () => {
    setAdvanced(!advanced)
  }
  return (
    <>
      <Header />
      <section className="main_dashboard d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="main__reports">
                <div className="row justify-content-center">
                  <Slider {...banner}>
                    <div className="dashboard__report__icon" data-aos="fade-up" data-aos-delay="100">
                      <div className="report__grid">
                        <div className="icon">
                          <img src="assets/img/icons/over-speed.svg" alt="" />
                        </div>
                        <div className="title">
                          <h2>حالات تجاوز السرعة</h2>
                        </div>
                        <div className="count">
                          <h3>113</h3>
                        </div>
                      </div>
                    </div>
                    <div className="dashboard__report__icon" data-aos="fade-up" data-aos-delay="200">
                      <div className="report__grid">
                        <div className="icon">
                          <img src="assets/img/icons/accident-report.svg" alt="" />
                        </div>
                        <div className="title">
                          <h2>تقارير الحادث</h2>
                        </div>
                        <div className="count">
                          <h3>17</h3>
                        </div>
                      </div>
                    </div>
                    <div className="dashboard__report__icon" data-aos="fade-up" data-aos-delay="300">
                      <div className="report__grid">
                        <div className="icon">
                          <img src="assets/img/icons/no-accident.svg" alt="" />
                        </div>
                        <div className="title">
                          <h2>إجمالي عدد الحوادث</h2>
                        </div>
                        <div className="count">
                          <h3>29</h3>
                        </div>
                      </div>
                    </div>
                    <div className="dashboard__report__icon" data-aos="fade-up" data-aos-delay="200">
                      <div className="report__grid">
                        <div className="icon">
                          <img src="assets/img/icons/accident-report.svg" alt="" />
                        </div>
                        <div className="title">
                          <h2>تقارير الحادث</h2>
                        </div>
                        <div className="count">
                          <h3>17</h3>
                        </div>
                      </div>
                    </div>
                    <div className="dashboard__report__icon" data-aos="fade-up" data-aos-delay="300">
                      <div className="report__grid">
                        <div className="icon">
                          <img src="assets/img/icons/no-accident.svg" alt="" />
                        </div>
                        <div className="title">
                          <h2>إجمالي عدد الحوادث</h2>
                        </div>
                        <div className="count">
                          <h3>29</h3>
                        </div>
                      </div>
                    </div>

                  </Slider>
                </div>
                <div className="report__search" data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="200"
                  data-aos-offset="0">
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div className="mainSearch">
                        <div className="input-group">
                          <input type="text" className="form-control bg-transparent border-0" placeholder="ابحث هنا" aria-label="ابحث هنا" aria-describedby="button-addon2" />
                          <div className="input-group-append">
                            <button className="btn btn-transparent border-0" type="button" id="button-addon2"><img src="assets/img/icons/search.svg" alt="Search" /></button>
                          </div>
                        </div>
                        <button className='btn btn-search'>search</button>
                      </div>
                    </div>
                    <div className={advanced ? `advanced__search darkBg` : 'advanced__search'}>
                      <button className='btn btn-sm advanced-search' onClick={() => advancedSearch()}> Advance search <img src="assets/img/icons/arrowleft-circle.svg" alt="Search" /></button>
                      {
                        advanced &&
                        <div className="search__details" data-aos="fade-zoom-in">
                          <div className="row align-items-end">
                            <div className="col-md-3">
                              <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control type="date" placeholder="Start Date" />
                              </Form.Group>
                            </div>
                            <div className="col-md-3">
                              <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control type="date" placeholder="End Date" />
                              </Form.Group>
                            </div>
                            <div className="col-md-5">
                              <Form.Group className="mb-3" controlId="formBasicPassword">

                                <Form.Control type="text" placeholder="Latest News" />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row">
                            <div className="selected__tags">
                              <div className="tags">Latest News <i className="bi bi-x"></i></div>
                              <div className="tags">Latest News <i className="bi bi-x"></i></div>
                              <div className="tags">Latest News <i className="bi bi-x"></i></div>
                              <div className="tags">Latest News <i className="bi bi-x"></i></div>
                              <div className="tags">Latest News <i className="bi bi-x"></i></div>
                              <div className="tags">Latest News <i className="bi bi-x"></i></div>
                              <div className="tags">Latest News <i className="bi bi-x"></i></div>
                              <div className="tags">Latest News <i className="bi bi-x"></i></div>
                              <div className="tags">Latest News <i className="bi bi-x"></i></div>

                            </div>
                          </div>
                        </div>
                      }

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="message__section" data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="300"
          data-aos-offset="0">
          <div className="container p-0">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="news__marquee">
                  <div className="title">
                    <img src="assets/img/icons/notification-danger.svg" alt="Notificationdanger" />
                    <span>رسالة مهمة </span>
                  </div>
                  <Marquee pauseOnHover={true} direction='right' speed={100} gradient={false} loop={0}>
                    <ul>
                      <li> <a href="assets/pdf/sample.pdf" download>و سأعرض مثال حي لهذا، من منا لم يتحمل جهد بدني شاق إلا من أجل الحصول على ميزة أو فائدة؟ ولكن من لديه الحق أن ينتقد شخص ما أراد أن يشعر بالسعادة التي لا تشوبها عواقب أليمة أو آخر أراد أن يتجنب الألم الذي ربما تنجم عنه بعض المتعة ؟</a></li>
                    </ul>
                  </Marquee>
                  <div className="viewAll">
                    <Link to='/'>View all</Link>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="news_poll">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-tab-6">
              <div className="news__grid" data-aos="fade-up">
                <div className="default__heading">
                  <h1>Latest News</h1>
                  <DropdownButton id="dropdown-item-button" title="جميع الإدارات">
                    <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
                    <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
                    <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
                  </DropdownButton>
                </div>
                <Slider {...news}>
                  <div className="news__card">
                    <div className="card">
                      <div className="news__image">
                        <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                        <div className="date">
                          <h4>03 Mar</h4>
                        </div>
                      </div>
                      <div className="card-body">
                      <div className="top__lists">
                          <div className="grid">
                            <p>مسؤل</p>
                            <img src="assets/img/icons/news-admin.svg" alt="" />
                          </div>
                          <div className="grid">
                          <p>12 تعليق</p>
                            <img src="assets/img/icons/news-comment.svg" alt="" />
                          </div>
                        </div>
                        <div className="news__title">
                          <h2>لا يوجد من يرغب في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد تكمن السعاده فيما نتحمله من كد وأسي.</h2>
                        </div>
                        <div className="moreDetails">
                          <Link to={'/newsDetails'} className="btn btn-primary">Read more</Link>
                          <div className="likedCount">
                          <img src="assets/img/icons/heart.svg" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="news__card">
                    <div className="card">
                      <div className="news__image">
                        <img src="assets/img/news/news1.jpg" className="card-img-top" alt="..." />
                        <div className="date">
                          <h4>08 Mar</h4>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="top__lists">
                          <div className="grid">
                            <p>مسؤل</p>
                            <img src="assets/img/icons/news-admin.svg" alt="" />
                          </div>
                          <div className="grid">
                          <p>12 تعليق</p>
                            <img src="assets/img/icons/news-comment.svg" alt="" />
                          </div>
                        </div>
                        <div className="news__title">
                          <h2>لا يوجد من يرغب في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد تكمن السعاده فيما نتحمله من كد وأسي.</h2>
                        </div>
                        <div className="moreDetails">
                          <Link to={'/newsDetails'} className="btn btn-primary">Read more</Link>
                          <div className="likedCount">
                          <h4>155</h4>
                          <img src="assets/img/icons/heartfill.svg" alt="" />
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* SLIDE TWO */}
                  <div className="news__card">
                    <div className="card">
                      <div className="news__image">
                        <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                        <div className="date">
                          <h4>03 Mar</h4>
                        </div>
                      </div>
                      <div className="card-body">
                      <div className="top__lists">
                          <div className="grid">
                            <p>مسؤل</p>
                            <img src="assets/img/icons/news-admin.svg" alt="" />
                          </div>
                          <div className="grid">
                          <p>12 تعليق</p>
                            <img src="assets/img/icons/news-comment.svg" alt="" />
                          </div>
                        </div>
                        <div className="news__title">
                          <h2>لا يوجد من يرغب في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد تكمن السعاده فيما نتحمله من كد وأسي.</h2>
                        </div>
                        <div className="moreDetails">
                          <Link to={'/newsDetails'} className="btn btn-primary">Read more</Link>
                          <div className="likedCount">
                          <h4>155</h4>
                          <img src="assets/img/icons/heartfill.svg" alt="" />
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="news__card">
                    <div className="card">
                      <div className="news__image">
                        <img src="assets/img/news/news1.jpg" className="card-img-top" alt="..." />
                        <div className="date">
                          <h4>08 Mar</h4>
                        </div>
                      </div>
                      <div className="card-body">
                      <div className="top__lists">
                          <div className="grid">
                            <p>مسؤل</p>
                            <img src="assets/img/icons/news-admin.svg" alt="" />
                          </div>
                          <div className="grid">
                          <p>12 تعليق</p>
                            <img src="assets/img/icons/news-comment.svg" alt="" />
                          </div>
                        </div>
                        <div className="news__title">
                          <h2>لا يوجد من يرغب في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد تكمن السعاده فيما نتحمله من كد وأسي.</h2>
                        </div>
                        <div className="moreDetails">
                          <Link to={'/newsDetails'} className="btn btn-primary">Read more</Link>
                          <div className="likedCount">
                          <h4>155</h4>
                          <img src="assets/img/icons/heartfill.svg" alt="" />
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
                <div className="view__more">
                  <Link to={'/newsLists'}>view all</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-tab-6">
              <div className="default__heading">
                <h1>Survey</h1>
              </div>
              <div className="card pollCard" data-aos="fade-up" data-aos-delay="100">
                <div className="card-body">
                  <div className="poll__header">
                    <div className="title">
                      <p>Lorem ipsum dolor sit amet</p>
                    </div>
                  </div>
                  <div className="poll__details">
                    <h2>?Lorem ipsum dolor sit amet</h2>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll1">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll1" />
                    </div>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll2">
                        Lorem ipsum dolor sit amet de
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll2" />
                    </div>

                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll3">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll3" />
                    </div>

                    <div className="poll__btn">
                      <button className="btn btn-default">Next</button>
                      <div className="pageCountt"><h4>1/6</h4></div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="view__more">
                <Link to={'/pollLists'}>view all</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="events__announcements">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="events__announcements__grid">
                <div className="default__heading">
                  <h1>Announcements</h1>
                  <DropdownButton id="dropdown-item-button" title="جميع الإدارات">
                    <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
                    <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
                    <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
                  </DropdownButton>
                </div>

                <div className="card boxshadow-0 pl-0" data-aos="fade-up" data-aos-delay="100">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-tab-12">
                      <div className="event__image">
                        <img src="assets/img/announcements/1.jpg" className="img-fluid" alt="..." />
                      </div>
                    </div>
                    <div className="col-md-9 col-tab-12">
                      <div className="card-body">
                        <p className="card-text"><small className="text-muted">May 30, 2022 at 1:11 pm</small></p>
                        <Link to='/announcementsDetail'><h2>سيتم استبدال هذا المحتوى بالمحتوى الأصلي</h2></Link>
                        <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="card boxshadow-0 pl-0 pt-0" data-aos="fade-up" data-aos-delay="200">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-tab-12">
                      <div className="event__image">
                        <img src="assets/img/announcements/2.jpg" className="img-fluid" alt="..." />
                      </div>
                    </div>
                    <div className="col-md-9 col-tab-12">
                      <div className="card-body">
                        <p className="card-text"><small className="text-muted">May 30, 2022 at 1:11 pm</small></p>
                        <Link to='/announcementsDetail'><h2>سيتم استبدال هذا المحتوى بالمحتوى الأصلي</h2></Link>
                        <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="card boxshadow-0 pl-0 pt-0" data-aos="fade-up" data-aos-delay="300">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-tab-12">
                      <div className="event__image">
                        <img src="assets/img/announcements/3.jpg" className="img-fluid" alt="..." />
                      </div>
                    </div>
                    <div className="col-md-9 col-tab-12">
                      <div className="card-body border-0">
                        <p className="card-text"><small className="text-muted">May 30, 2022 at 1:11 pm</small></p>
                        <Link to='/announcementsDetail'><h2>سيتم استبدال هذا المحتوى بالمحتوى الأصلي</h2></Link>
                        <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="view__more pt-0">
                <Link to={'/announcements'}>view all</Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="events__announcements__grid">
                <div className="default__heading">
                  <h1>Events</h1>
                </div>
                <div className="card boxshadow-0 pl-0" data-aos="fade-up" data-aos-delay="100">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-tab-12">
                      <div className="event__image">
                        <img src="assets/img/announcements/1.jpg" className="img-fluid" alt="..." />
                      </div>
                    </div>
                    <div className="col-md-9 col-tab-12">
                      <div className="card-body event">
                         <Link to='/eventDetail'><h2 className='pt-0 pb-2'>Lorem ipsum dolor sit amet</h2></Link>
                        <div className="news__announcement-content">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card boxshadow-0 pl-0 pt-0" data-aos="fade-up" data-aos-delay="200">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-tab-12">
                      <div className="event__image">
                        <img src="assets/img/announcements/2.jpg" className="img-fluid" alt="..." />
                      </div>
                    </div>
                    <div className="col-md-9 col-tab-12">
                      <div className="card-body event">
                         <Link to='/eventDetail'><h2 className='pt-0 pb-2'>Lorem ipsum dolor sit amet</h2></Link>
                        <div className="news__announcement-content">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card boxshadow-0 pl-0 pt-0" data-aos="fade-up" data-aos-delay="300">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-tab-12">
                      <div className="event__image">
                        <img src="assets/img/announcements/3.jpg" className="img-fluid" alt="..." />
                      </div>
                    </div>
                    <div className="col-md-9 col-tab-12">
                      <div className="card-body event">
                        <Link to='/eventDetail'><h2 className='pt-0 pb-2'>Lorem ipsum dolor sit amet</h2></Link>
                        <div className="news__announcement-content">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="view__more pt-0">
                <Link to={'/events'}>view all</Link>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className="container training__suggestions">
          <div className="row">
            <div className="col-md-6">
                <div className="default__heading">
                  <h1>Training</h1>
                </div>
                <div className="trainingGrids" data-aos="fade-up">
                 <div className="title">
                    <h3>Lorem ipsum dolor sit amet</h3>
                    <p>May 30, 2022</p>
                 </div>
                 <div className="description">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget</p>
                  <div className="text-left"><button className="btn-sm btn-primary">apply now</button></div>
                 </div>
                </div>
                <div className="trainingGrids" data-aos="fade-up">
                 <div className="title">
                    <h3>Lorem ipsum dolor sit amet</h3>
                    <p>May 30, 2022</p>
                 </div>
                 <div className="description">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget.</p>
                 <div className="text-left"><button className="btn-sm btn-primary">apply now</button></div>
                 </div>
                </div>
                <div className="view__more pt-4">
                  <Link to={'/training'}>View All</Link>
                </div>
            </div>
            <div className="col-md-6">
            <div className="default__heading">
                  <h1>Suggestions</h1>
                </div>
              <div className="suggestionsGrid">
                <Form.Control as="textarea" placeholder="Write your Suggestions" />
                <div className="buttons">
                  <div className="actions">
                    <button className="btn btn-common">Submit</button>
                    <button className="btn btn-danger mr-3">Cancel</button>
                  </div>
                  <div className="upload">
                  <div className="upload-btn-wrapper">
                      <button className="btn uploadBtn">Upload <img src="assets/img/icons/btn-upload.svg" alt="" /></button>
                      <input type="file" name="myfile" />
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row">
            <div className="col-md-7">
              <div className="quick__access">
                <div className="default__heading">
                  <h1>Quick Access</h1>
                </div>

                <Slider {...settings}>

                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/3.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/2.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/1.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/4.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/5.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/6.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/7.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/8.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/9.png" alt="" />
                      </div>
                    </a>
                  </div>

                  {/* NEXT */}
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/3.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/2.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/1.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/4.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/5.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/6.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/7.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/8.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="item__grid" data-aos="fade-up">
                    <a href="#">
                      <div className="logo">
                        <img src="assets/img/logos/9.png" alt="" />
                      </div>
                    </a>
                  </div>

                </Slider>
              </div>
            </div>
            <div className="col-md-5">
              <div className="vaccancies">
                <div className="default__heading">
                  <h1>Internal Vacancies</h1>
                </div>

                <div className="col-md-12" data-aos="fade-up" data-aos-delay="100">
                  <div className="vacancy__grid">
                    <h2>مدير تكنولوجيا المعلومات</h2>
                    <div className="apply">
                      <p>استبدل هذا النص استبدل هذا النص</p>
                      <button className="btn-sm btn-primary">view details</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-12" data-aos="fade-up" data-aos-delay="200">
                  <div className="vacancy__grid">
                    <h2>الموارد البشرية</h2>
                    <div className="apply">
                      <p>استبدل هذا النص استبدل هذا النص استبدل هذا النص استبدل هذا النص</p>
                      <button className="btn-sm btn-primary">view details</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-12" data-aos="fade-up" data-aos-delay="300">
                  <div className="vacancy__grid">
                    <h2>أخصائي تدريب</h2>
                    <div className="apply">
                      <p>استبدل هذا النص استبدل هذا النص</p>
                      <button className="btn-sm btn-primary">view details</button>
                    </div>
                  </div>
                </div>

                <div className="col-md-12" data-aos="fade-up" data-aos-delay="400">
                  <div className="vacancy__grid border-0">
                    <h2>قائد العمليات</h2>
                    <div className="apply">
                      <p>استبدل هذا النص استبدل هذا النص</p>
                      <button className="btn-sm btn-primary">view details</button>
                    </div>
                  </div>
                </div>
                <div className="view__more pt-0">
                  <Link to={'/vaccancy'}>view all</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-tab-6">
            <div className="default__heading">
                  <h1>Initiatives</h1>
              </div>
              <div className="initiatives__section">
                <div className="initiativesGrid">
                  <h6>May 30, 2022 at 1:11 pm</h6>
                  <div className="title">
                    <h2>Maintenance of lorem Ipsum</h2>
                    <div className="names">
                      <div className="list"><h4>@JOHN DOE</h4></div>
                      <div className="list"><h4>@JOHN DOE</h4></div>
                    </div>
                  </div>
                  <div className="description">
                      <p>Lorem ipsum dolor sit amet orem ipsum dolor sit Lorem ipsum dolor sit amet</p>
                      <button className="btn-sm btn-primary">view details</button>
                    </div>
                </div>
                <div className="initiativesGrid">
                  <h6>May 30, 2022 at 1:11 pm</h6>
                  <div className="title">
                    <h2>Maintenance of lorem Ipsum</h2>
                    <div className="names">
                      <div className="list"><h4>@JOHN DOE</h4></div>
                    </div>
                  </div>
                  <div className="description">
                      <p>Lorem ipsum dolor sit amet orem ipsum dolor sit Lorem ipsum dolor sit amet</p>
                      <button className="btn-sm btn-primary">view details</button>
                    </div>
                </div>
                <div className="initiativesGrid">
                  <h6>May 30, 2022 at 1:11 pm</h6>
                  <div className="title">
                    <h2>Maintenance of lorem Ipsum</h2>
                    <div className="names">
                      <div className="list"><h4>@JOHN DOE</h4></div>
                    </div>
                  </div>
                  <div className="description">
                      <p>Lorem ipsum dolor sit amet orem ipsum dolor sit Lorem ipsum dolor sit amet</p>
                      <button className="btn-sm btn-primary">view details</button>
                    </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-tab-6">
              <div className="evaluation__section">
              <div className="default__heading">
                  <h1>Evaluation</h1>
              </div>
              <div className="card">
              <div className="card-body">
                  <div className="evaluationGrid">
                    <div className="contents">
                    <h4>Donec augue massa, pharetra sit amet feugiat at</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tincidunt venenatis blandit. Cras facilisis blandit arcu id semper.</p>
                    <h6>May 30, 2022 at 1:11 pm</h6>
                    </div>
                    <div className="evaluationActions">
                      <button className='btn btn-common'>View Details</button>
                      <button className='btn btn-danger'>Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tsd__reports bg-white pt-3">
      <div className="container">
          <div className="row">
          <div className="default__heading mb-30">
            <div className="transport">
              <h1>TSD Reports</h1>
              <h4><i className="bi bi-chevron-left"></i> Transport/Tram</h4>
            </div>
                  <DropdownButton id="dropdown-item-button" title="Transport">
                    <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
                    <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
                    <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
                  </DropdownButton>
                </div>
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-md-6 col-tab-12">
                    <Chart/>
                    <div className="footer">
                      <div className="right">
                        <p>consectetuer adipiscing elit, sed, consectetuer adipiscing elit, sed</p>
                      </div>
                      <div className="left">
                        <h3>lorem ipsum</h3>
                        <p>lorem ipsum</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-tab-12">
                    <div className="pieChart">
                    <Piechart/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="benefitsActivities bg-white pt-3">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6">
              <div className="default__heading mb-30">
                 <h1>Benefits</h1>
              </div>
              <div className="benefitsActivityGrid">
              <Link to='/benefits'><h3>Lorem ipsum dolor sit amet</h3></Link>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar.</p>
                <button className="btn-sm btn-primary">view details</button>
              </div>
              <div className="benefitsActivityGrid">
              <Link to='/benefits'><h3>Lorem ipsum dolor sit amet</h3></Link>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar.</p>
                <button className="btn-sm btn-primary">view details</button>
              </div>
              <div className="benefitsActivityGrid">
                <Link to='/benefits'><h3>Lorem ipsum dolor sit amet</h3></Link>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar.</p>
                <button className="btn-sm btn-primary">view details</button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="default__heading mb-30">
                 <h1>Activites</h1>
              </div>
              <div className="benefitsActivityGrid">
              <Link to='/activities'><h3>Lorem ipsum dolor sit amet</h3></Link>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar.</p>
                <button className="btn-sm btn-primary">Join</button>
              </div>
              <div className="benefitsActivityGrid">
                <Link to='/activities'><h3>Lorem ipsum dolor sit amet</h3></Link>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar.</p>
                <button className="btn-sm btn-primary">Join</button>
              </div>
              <div className="benefitsActivityGrid">
              <Link to='/activities'><h3>Lorem ipsum dolor sit amet</h3></Link>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar.</p>
                <button className="btn-sm btn-primary">Join</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="communication__hub bg-white" data-aos="fade-up" data-aos-delay="100">
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-tab-6">
              <div className="communication">
                <div className="default__heading">
                  <h1>Discussions</h1>
                </div>

                <div className="communication__form">
                  <div className="card__form">
                    <div className="form-group">
                      <Form.Control type="email" placeholder="اكتب العنوان" />
                    </div>
                    <div className="form-group">
                      <Form.Control
                        as="textarea"
                        placeholder="أكتب شيئا"
                        style={{ height: '100px' }}
                      />
                    </div>
                    <div className="form-group submit__btn">
                      <div className="upload-btn-wrapper">
                        <button className="btn btn-upload"><img src="assets/img/icons/upload.svg" alt="" />Photos</button>
                      </div>
                      <div className="upload-btn-wrapper">
                      <select class="form-select sm-select" aria-label="Default select example">
                        <option selected>Department</option>
                        <option value="1">Department</option>
                        <option value="2">Department</option>
                        <option value="3">Department</option>
                      </select>
                      </div>
                      <div className="upload-btn-wrapper">
                        <button className="btn btn-upload"><img src="assets/img/icons/user-sm.svg" alt="" />User</button>
                      </div>
                      <button type="submit" className="btn btn-primary btn-post">Post</button>
                    </div>
                    <div className="row postResults">
                            <div className="selected__tags">
                              <div className="tags">John Doe <i className="bi bi-x"></i></div>
                              <div className="tags">Job <i className="bi bi-x"></i></div>
                              <div className="tags">Latest News <i className="bi bi-x"></i></div>
                              <div className="tags">Latest News <i className="bi bi-x"></i></div>
                              <div className="tags">Latest News <i className="bi bi-x"></i></div>
                              <div className="tags">Latest <i className="bi bi-x"></i></div>

                            </div>
                          </div>
                  </div>
                </div>
                <div className="add__comment__section">
                <div className="comments__grid">
                  <div className="header">
                    <div className="avatar">
                      {/* <img src="assets/img/icons/user.svg" alt="" /> */}
                      <h4>لوي روبن</h4>
                    </div>
                    <div className="time">
                      <h4>1 دقيقة مضت</h4>
                    </div>
                  </div>
                  <div className="comment">
                    <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.</p>
                  </div>
                  <div className="comment__count">
                    <p>14 تعليق</p>
                  </div>
                </div>
                <div className="comments__grid">
                  <div className="header">
                    <div className="avatar">
                      <h4>جين دو</h4>
                    </div>
                    <div className="time">
                      <h4>1 دقيقة مضت</h4>
                    </div>
                  </div>
                  <div className="comment">
                    <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.</p>
                  </div>
                  <div className="comment__count">
                    <p>14 تعليق</p>
                  </div>
                </div>
                <div className="comments__grid">
                  <div className="header">
                    <div className="avatar">
                      <h4>جين دو</h4>
                    </div>
                    <div className="time">
                      <h4>1 دقيقة مضت</h4>
                    </div>
                  </div>
                  <div className="comment">
                    <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.</p>
                    <img src="assets/img/comment.jpg" alt="" />
                  </div>
                  <div className="comment__count">
                    <p>14 تعليق</p>
                  </div>
                </div>
                </div>
                {/* <div className="comments__grid">
                  <div className="header">
                    <div className="avatar">
                      <h4>جين دو</h4>
                    </div>
                    <div className="time">
                      <h4>1 دقيقة مضت</h4>
                    </div>
                  </div>
                  <div className="comment">
                    <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.</p>
                  </div>
                  <div className="comment__count">
                    <p>14 تعليق</p>
                  </div>
                </div> */}
                <div className="view__more pt-0">
                  <Link to={'/'}>عرض كافة</Link>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-tab-6">
              <div className="leaderboard position-relative" data-aos="fade-up" data-aos-delay="100">
                <div className="default__heading">
                  <h1>Leaderboard</h1>
                </div>
                <div className="col-md-12">
                  <div className="board__grid active">
                    <div className="right__content">
                      <div className="number"><p>01</p></div>
                      <div className="user">
                        <h4>John Doe</h4>
                        <span>Gold</span>
                      </div>

                    </div>
                    <div className="medal">
                      <img src="assets/img/icons/gold.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="board__grid">
                    <div className="right__content">
                      <div className="number"><p>02</p></div>
                      <div className="user">
                        <h4>John Doe</h4>
                        <span>Silver</span>
                      </div>

                    </div>
                    <div className="medal">
                      <img src="assets/img/icons/silver.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="board__grid">
                    <div className="right__content">
                      <div className="number"><p>03</p></div>
                      <div className="user">
                        <h4>Andrews</h4>
                        <span>Silver</span>
                      </div>

                    </div>
                    <div className="medal">
                      <img src="assets/img/icons/silver.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="board__grid">
                    <div className="right__content">
                      <div className="number"><p>04</p></div>
                      <div className="user">
                        <h4>Stefen</h4>
                        <span>Silver</span>
                      </div>

                    </div>
                    <div className="medal">
                      <img src="assets/img/icons/light-gold.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="board__grid">
                    <div className="right__content">
                      <div className="number"><p>05</p></div>
                      <div className="user">
                        <h4>Jake Doe</h4>
                        <span>Silver</span>
                      </div>

                    </div>
                    <div className="medal">
                      <img src="assets/img/icons/light-gold.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="board__grid">
                    <div className="right__content">
                      <div className="number"><p>06</p></div>
                      <div className="user">
                        <h4>Jake Doe</h4>
                        <span>Silver</span>
                      </div>

                    </div>
                    <div className="medal">
                      <img src="assets/img/icons/light-gold.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="view__more pt-3">
                  <Link to={'/leaderboard'}>view all</Link>
                </div>
              </div>

              <div className="event__calendar position-relative mt-5 homeEvent__calendar" data-aos="fade-up" data-aos-delay="100">
                <div className="card">
                  <div className="card-body p-0">
                    {
                      search == true && <div className="homeCalendarSearch">
                          <div className="form-group">
                            <input type="text" className="form-control"/>
                          </div>
                          <button type="submit" className="btn btn-calendarsearch">search</button>
                      </div>
                    }
                    <div className="calendar__select">
                        <Form.Select>
                          <option>Calendar 1</option>
                          <option>Calendar 2</option>
                          <option>Calendar 3</option>
                        </Form.Select>
                    </div>
                    <FullCalendar
                      defaultView="dayGridMonth"
                      headerToolbar={{
                        right: "dropdown",
                        left: "addEvent search",
                        center: "title"
                      }}
                      themeSystem="Simplex"
                      plugins={[dayGridPlugin]}
                      events={events}
                      customButtons={{
                        addEvent: {
                          icon: `bi bi-plus-square-fill`,
                          click: handleShow
                        },
                        search: {
                          icon: `bi bi-search`,
                          click: searchShow
                        },
                        dropdown: ''

                      }}
                      locale="ar"

                    />

                    <div className="home__upcoming__events">
                      <div className="title"><h3>الأحداث القادمة</h3></div>
                      <div className="tab__grid">
                        <div className="title">
                          <h2>لقاء في جو</h2>
                          <p>10 يوليو 2022</p>
                        </div>
                        <div className="left__btn">
                          <button className='btn-time'>18:00</button>
                          <button className='btn-arrow'><img src="./assets/img/icons/arrow.svg" alt="" /></button>
                        </div>
                      </div>
                      <div className="tab__grid">
                        <div className="title">
                          <h2>لقاء في جو</h2>
                          <p>10 يوليو 2022</p>
                        </div>
                        <div className="left__btn">
                          <button className='btn-time'>18:00</button>
                          <button className='btn-arrow'><img src="./assets/img/icons/arrow.svg" alt="" /></button>
                        </div>
                      </div>
                      <div className="tab__grid">
                        <div className="title">
                          <h2>لقاء في جو</h2>
                          <p>10 يوليو 2022</p>
                        </div>
                        <div className="left__btn">
                          <button className='btn-time'>18:00</button>
                          <button className='btn-arrow'><img src="./assets/img/icons/arrow.svg" alt="" /></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="view__more pt-3">
                <Link to={'/calendar'}>view all</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq">
        <div className="container">
          <div className="default__heading">
            <h1>FAQ</h1>
          </div>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>كيف يمكننا المساعدة؟</Accordion.Header>
              <Accordion.Body>
                <h4>استبدل هذا النص استبدل هذا النص</h4>
                لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>استبدل هذا النص استبدل هذا النص</Accordion.Header>
              <Accordion.Body>
                لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>استبدل هذا النص استبدل هذا النص</Accordion.Header>
              <Accordion.Body>
                لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>استبدل هذا النص استبدل هذا النص</Accordion.Header>
              <Accordion.Body>
                لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>استبدل هذا النص استبدل هذا النص</Accordion.Header>
              <Accordion.Body>
                لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>


      <section className="image__gallery">
        <div className="container">
          <div className="default__heading">
            <h1>Gallery</h1>
          </div>
          <div className="row gallery__row">

            <div className="col-md-4">
              <div className="gallery__image">
                <div className="overlay"></div>
                <img src='assets/img/gallery/1.jpg' className='w-100 image' />
                <div className="date">
                  <p>07 Mar</p>
                </div>
                <div class="overlay">
                  <div className="hover">
                    <span>يوليو 2022</span>
                    <p>استبدل هذا النص استبدل هذا النص</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="gallery__image">
                <div className="overlay"></div>
                <img src='assets/img/gallery/2.jpg' className='w-100 image' />
                <div className="date">
                  <p>07 Mar</p>
                </div>
                <div class="overlay">
                  <div className="hover">
                    <span>يوليو 2022</span>
                    <p>استبدل هذا النص استبدل هذا النص</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="gallery__image">
                <div className="overlay"></div>
                <img src='assets/img/gallery/3.jpg' className='w-100 image' />
                <div className="date">
                  <p>07 Mar</p>
                </div>
                <div class="overlay">
                  <div className="hover">
                    <span>يوليو 2022</span>
                    <p>استبدل هذا النص استبدل هذا النص</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="gallery__image">
                <div className="overlay"></div>
                <img src='assets/img/gallery/4.jpg' className='w-100 image' />
                <div className="date">
                  <p>07 Mar</p>
                </div>
                <div class="overlay">
                  <div className="hover">
                    <span>يوليو 2022</span>
                    <p>استبدل هذا النص استبدل هذا النص</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="gallery__image">
                <div className="overlay"></div>
                <img src='assets/img/gallery/5.jpg' className='w-100 image' />
                <div className="date">
                  <p>07 Mar</p>
                </div>
                <div class="overlay">
                  <div className="hover">
                    <span>يوليو 2022</span>
                    <p>استبدل هذا النص استبدل هذا النص</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="gallery__image">
                <div className="overlay"></div>
                <img src='assets/img/gallery/6.jpg' className='w-100 image' />
                <div className="date">
                  <p>07 Mar</p>
                </div>
                <div class="overlay">
                  <div className="hover">
                    <span>يوليو 2022</span>
                    <p>استبدل هذا النص استبدل هذا النص</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-left">
              <Link to={'/gallery'} className="btn btn-primary">view all</Link>
            </div>
          </div>

        </div>
      </section>

      <Modal show={show} onHide={handleClose} size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>إضافة حدث</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="اسم الحدث" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <input type="time" className="form-control" placeholder="اسم الحدث" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <input type="date" className="form-control" placeholder="اسم الحدث" />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            إرسال
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer />

    </>
  )
}

export default Home