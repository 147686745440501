import React from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AddComments from '../components/AddComments'
import Footer from '../components/Footer'
import Header from '../components/Header'

function AnnouncementLists() {
  return (
    <>
    <Header/>
    <section className="tsd__details" style={{backgroundImage:"url(./assets/img/inner-banner.jpg)"}}>
        <div className="container">
            <div className="page__title">
                <div className="icon">
                    <img src="./assets/img/icons/announcement.svg" alt="" />
                </div>
                <h2>إعلان</h2>
            </div>
        </div>
      </section>
      <section className="news__details">
        <div className="container">
            <div className="row">
            <div className="default__heading justify-content-end mt-5">
            <DropdownButton id="dropdown-item-button" title="جميع الإدارات">
              <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
              <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
              <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
            </DropdownButton>
               </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-6 col-lg-6">
                <div className="events__announcements__grid">
        <div className="bg-transparent card boxshadow-0 pl-0" data-aos="fade-up" data-aos-delay="100">
            <div className="row align-items-center">
              <div className="col-md-3 col-tab-12">
                <div className="event__image">
                <img src="assets/img/announcements/1.jpg" className="img-fluid" alt="..." />
                </div>
              </div>
              <div className="col-md-9 col-tab-12">
                <div className="card-body">
                <p className="card-text"><small className="text-muted">May 30, 2022 at 1:11 pm</small></p>
                  <h2>سيتم استبدال هذا المحتوى بالمحتوى الأصلي</h2>
                  <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="bg-transparent card boxshadow-0 pl-0 pt-0" data-aos="fade-up" data-aos-delay="200">
            <div className="row align-items-center">
              <div className="col-md-3 col-tab-12">
                <div className="event__image">
                <img src="assets/img/announcements/2.jpg" className="img-fluid" alt="..." />
                </div>
              </div>
              <div className="col-md-9 col-tab-12">
                <div className="card-body">
                <p className="card-text"><small className="text-muted">May 30, 2022 at 1:11 pm</small></p>
                  <h2>سيتم استبدال هذا المحتوى بالمحتوى الأصلي</h2>
                  <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="bg-transparent card boxshadow-0 pl-0 pt-0 " data-aos="fade-up" data-aos-delay="300">
            <div className="row align-items-center">
              <div className="col-md-3 col-tab-12">
                <div className="event__image">
                <img src="assets/img/announcements/3.jpg" className="img-fluid" alt="..." />
                </div>
              </div>
              <div className="col-md-9 col-tab-12">
                <div className="card-body border-0">
                <p className="card-text"><small className="text-muted">May 30, 2022 at 1:11 pm</small></p>
                  <h2>سيتم استبدال هذا المحتوى بالمحتوى الأصلي</h2>
                  <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="bg-transparent card boxshadow-0 pl-0" data-aos="fade-up" data-aos-delay="100">
            <div className="row align-items-center">
              <div className="col-md-3 col-tab-12">
                <div className="event__image">
                <img src="assets/img/announcements/1.jpg" className="img-fluid" alt="..." />
                </div>
              </div>
              <div className="col-md-9 col-tab-12">
                <div className="card-body">
                <p className="card-text"><small className="text-muted">May 30, 2022 at 1:11 pm</small></p>
                  <h2>سيتم استبدال هذا المحتوى بالمحتوى الأصلي</h2>
                  <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="bg-transparent card boxshadow-0 pl-0 pt-0" data-aos="fade-up" data-aos-delay="200">
            <div className="row align-items-center">
              <div className="col-md-3 col-tab-12">
                <div className="event__image">
                <img src="assets/img/announcements/2.jpg" className="img-fluid" alt="..." />
                </div>
              </div>
              <div className="col-md-9 col-tab-12">
                <div className="card-body">
                <p className="card-text"><small className="text-muted">May 30, 2022 at 1:11 pm</small></p>
                  <h2>سيتم استبدال هذا المحتوى بالمحتوى الأصلي</h2>
                  <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="bg-transparent card boxshadow-0 pl-0 pt-0 " data-aos="fade-up" data-aos-delay="300">
            <div className="row align-items-center">
              <div className="col-md-3 col-tab-12">
                <div className="event__image">
                <img src="assets/img/announcements/3.jpg" className="img-fluid" alt="..." />
                </div>
              </div>
              <div className="col-md-9 col-tab-12">
                <div className="card-body border-0">
                <p className="card-text"><small className="text-muted">May 30, 2022 at 1:11 pm</small></p>
                  <h2>سيتم استبدال هذا المحتوى بالمحتوى الأصلي</h2>
                  <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
                  
                </div>
              </div>
            </div>
          </div>

        </div>
                </div>
                <div className="col-md-6 col-lg-6">
                <div className="events__announcements__grid">
        <div className="bg-transparent card boxshadow-0 pl-0" data-aos="fade-up" data-aos-delay="100">
            <div className="row align-items-center">
              <div className="col-md-3 col-tab-12">
                <div className="event__image">
                <img src="assets/img/announcements/1.jpg" className="img-fluid" alt="..." />
                </div>
              </div>
              <div className="col-md-9 col-tab-12">
                <div className="card-body">
                <p className="card-text"><small className="text-muted">May 30, 2022 at 1:11 pm</small></p>
                  <h2>سيتم استبدال هذا المحتوى بالمحتوى الأصلي</h2>
                  <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="bg-transparent card boxshadow-0 pl-0 pt-0" data-aos="fade-up" data-aos-delay="200">
            <div className="row align-items-center">
              <div className="col-md-3 col-tab-12">
                <div className="event__image">
                <img src="assets/img/announcements/2.jpg" className="img-fluid" alt="..." />
                </div>
              </div>
              <div className="col-md-9 col-tab-12">
                <div className="card-body">
                <p className="card-text"><small className="text-muted">May 30, 2022 at 1:11 pm</small></p>
                  <h2>سيتم استبدال هذا المحتوى بالمحتوى الأصلي</h2>
                  <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="bg-transparent card boxshadow-0 pl-0 pt-0 " data-aos="fade-up" data-aos-delay="300">
            <div className="row align-items-center">
              <div className="col-md-3 col-tab-12">
                <div className="event__image">
                <img src="assets/img/announcements/3.jpg" className="img-fluid" alt="..." />
                </div>
              </div>
              <div className="col-md-9 col-tab-12">
                <div className="card-body border-0">
                <p className="card-text"><small className="text-muted">May 30, 2022 at 1:11 pm</small></p>
                  <h2>سيتم استبدال هذا المحتوى بالمحتوى الأصلي</h2>
                  <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="bg-transparent card boxshadow-0 pl-0" data-aos="fade-up" data-aos-delay="100">
            <div className="row align-items-center">
              <div className="col-md-3 col-tab-12">
                <div className="event__image">
                <img src="assets/img/announcements/1.jpg" className="img-fluid" alt="..." />
                </div>
              </div>
              <div className="col-md-9 col-tab-12">
                <div className="card-body">
                <p className="card-text"><small className="text-muted">May 30, 2022 at 1:11 pm</small></p>
                  <h2>سيتم استبدال هذا المحتوى بالمحتوى الأصلي</h2>
                  <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="bg-transparent card boxshadow-0 pl-0 pt-0" data-aos="fade-up" data-aos-delay="200">
            <div className="row align-items-center">
              <div className="col-md-3 col-tab-12">
                <div className="event__image">
                <img src="assets/img/announcements/2.jpg" className="img-fluid" alt="..." />
                </div>
              </div>
              <div className="col-md-9 col-tab-12">
                <div className="card-body">
                <p className="card-text"><small className="text-muted">May 30, 2022 at 1:11 pm</small></p>
                  <h2>سيتم استبدال هذا المحتوى بالمحتوى الأصلي</h2>
                  <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="bg-transparent card boxshadow-0 pl-0 pt-0 " data-aos="fade-up" data-aos-delay="300">
            <div className="row align-items-center">
              <div className="col-md-3 col-tab-12">
                <div className="event__image">
                <img src="assets/img/announcements/3.jpg" className="img-fluid" alt="..." />
                </div>
              </div>
              <div className="col-md-9 col-tab-12">
                <div className="card-body border-0">
                <p className="card-text"><small className="text-muted">May 30, 2022 at 1:11 pm</small></p>
                  <h2>سيتم استبدال هذا المحتوى بالمحتوى الأصلي</h2>
                  <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
                  
                </div>
              </div>
            </div>
          </div>

        </div>
                </div>
            </div>

            <div className="text-center"><Link to={'/'} className="btn btn-primary">عرض كافة</Link></div>
        </div>
      </section>
      <section className="bg-white">
        <div className="container">
        <AddComments/>
        </div>
      </section>

    <Footer/>
    </>
  )
}

export default AnnouncementLists