import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

function PollLists() {
  let survey = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    rtl: true,
    nextArrow: <img src='assets/img/icons/slidearrowLeft.svg' />,
    prevArrow: <img src='assets/img/icons/slidearrowRight.svg' />
  };
  return (
    <>
      <Header />
      <section className="tsd__details" style={{ backgroundImage: "url(./assets/img/inner-banner.jpg)" }}>
        <div className="container">
          <div className="page__title">
            <div className="icon">
              <img src="./assets/img/icons/pollLists.svg" alt="" />
            </div>
            <h2>أحدث الأخبار</h2>
          </div>
        </div>
      </section>
      <section className="news_poll poll__lists surveyDetails">
        <div className="container">
        <Slider {...survey}>
          <div className="survay__card">
            <div className="slideCount"><h3>1</h3></div>
            <div className="survay__title">
              <h2>Lorem ipsum dolor sit amet</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar.</p>
            </div>
            <div className="row">
              <div className="col-md-4 col-tab-6">
                <div className="card surveyCard">
                  <div className="card-body">
                    <span>1</span>
                    <div className="poll__details">
                    <h2>?Lorem ipsum dolor sit amet</h2>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll1">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll1" />
                    </div>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll2">
                        Lorem ipsum dolor sit amet de
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll2" />
                    </div>

                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll3">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll3" />
                    </div>

                  </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-tab-6">
                <div className="card surveyCard">
                  <div className="card-body">
                    <span>2</span>
                    <div className="poll__details">
                    <h2>?Lorem ipsum dolor sit amet</h2>
                   <textarea name="" className='form-control' placeholder='type here..' rows="6"></textarea>

                  </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-tab-6">
                <div className="card surveyCard">
                  <div className="card-body">
                    <span>3</span>
                    <div className="poll__details">
                    <h2>?Lorem ipsum dolor sit amet</h2>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll1">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll1" />
                    </div>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll2">
                        Lorem ipsum dolor sit amet de
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll2" />
                    </div>

                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll3">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll3" />
                    </div>

                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-md-4 col-tab-6">
                <div className="card surveyCard">
                  <div className="card-body">
                    <span>4</span>
                    <div className="poll__details">
                    <h2>?Lorem ipsum dolor sit amet</h2>
                   <textarea name="" className='form-control' placeholder='type here..' rows="6"></textarea>

                  </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-tab-6">
                <div className="card surveyCard">
                  <div className="card-body">
                    <span>5</span>
                    <div className="poll__details">
                    <h2>?Lorem ipsum dolor sit amet</h2>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll1">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll1" />
                    </div>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll2">
                        Lorem ipsum dolor sit amet de
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll2" />
                    </div>

                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll3">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll3" />
                    </div>

                  </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-tab-6">
                <div className="card surveyCard">
                  <div className="card-body">
                    <span>6</span>
                    <div className="poll__details">
                    <h2>?Lorem ipsum dolor sit amet</h2>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll1">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll1" />
                    </div>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll2">
                        Lorem ipsum dolor sit amet de
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll2" />
                    </div>

                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll3">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll3" />
                    </div>

                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="text-left">
                <button className='btn btn-common'>submit</button>
              </div>
            </div>
          </div>
          <div className="survay__card">
          <div className="slideCount"><h3>2</h3></div>
            <div className="survay__title">
              <h2>Lorem ipsum dolor sit amet</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar.</p>
            </div>
            <div className="row">
              <div className="col-md-4 col-tab-6">
                <div className="card surveyCard">
                  <div className="card-body">
                    <span>1</span>
                    <div className="poll__details">
                    <h2>?Lorem ipsum dolor sit amet</h2>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll1">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll1" />
                    </div>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll2">
                        Lorem ipsum dolor sit amet de
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll2" />
                    </div>

                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll3">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll3" />
                    </div>

                  </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-tab-6">
                <div className="card surveyCard">
                  <div className="card-body">
                    <span>2</span>
                    <div className="poll__details">
                    <h2>?Lorem ipsum dolor sit amet</h2>
                   <textarea name="" className='form-control' placeholder='type here..' rows="6"></textarea>

                  </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-tab-6">
                <div className="card surveyCard">
                  <div className="card-body">
                    <span>3</span>
                    <div className="poll__details">
                    <h2>?Lorem ipsum dolor sit amet</h2>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll1">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll1" />
                    </div>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll2">
                        Lorem ipsum dolor sit amet de
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll2" />
                    </div>

                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll3">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll3" />
                    </div>

                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-md-4 col-tab-6">
                <div className="card surveyCard">
                  <div className="card-body">
                    <span>4</span>
                    <div className="poll__details">
                    <h2>?Lorem ipsum dolor sit amet</h2>
                   <textarea name="" className='form-control' placeholder='type here..' rows="6"></textarea>

                  </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-tab-6">
                <div className="card surveyCard">
                  <div className="card-body">
                    <span>5</span>
                    <div className="poll__details">
                    <h2>?Lorem ipsum dolor sit amet</h2>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll1">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll1" />
                    </div>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll2">
                        Lorem ipsum dolor sit amet de
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll2" />
                    </div>

                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll3">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll3" />
                    </div>

                  </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-tab-6">
                <div className="card surveyCard">
                  <div className="card-body">
                    <span>6</span>
                    <div className="poll__details">
                    <h2>?Lorem ipsum dolor sit amet</h2>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll1">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll1" />
                    </div>
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll2">
                        Lorem ipsum dolor sit amet de
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll2" />
                    </div>

                    <div className="form-check">
                      <label className="form-check-label" htmlFor="poll3">
                        Lorem ipsum dolor sit amet
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="poll3" />
                    </div>

                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="text-left">
                <button className='btn btn-common'>submit</button>
              </div>
            </div>
          </div>
        </Slider>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default PollLists