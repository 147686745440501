import React from 'react'
import { useState } from 'react'
import ReactApexChart from 'react-apexcharts'

function Chart() {
    const [series, setSeries] = useState([{
        data: [0, 75, 50, 28, 70, 90],
      }])
    const [options, setOptions] = useState(
        {
            chart: {
              height: 150,
              type: 'bar',
              toolbar:false
            },
            colors:['#31D68F'],
            plotOptions: {
              bar: {
                columnWidth: '10%',
                distributed: true,
                borderRadius: 4,
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            xaxis: {
              labels: {
                style: {
                colors:['#31D68F'],
                  fontSize: '12px'
                }
              }
            },
          }
    )
  return (
    <div id="chart">
  <ReactApexChart options={options} series={series} type="bar" height={350} />
</div>
  )
}

export default Chart