import React from 'react'
import { useState } from 'react'
import ReactApexChart from 'react-apexcharts'

function Piechart() {
    const [options, setOptions] = useState({
        chart: {
          type: 'donut',
          height: 150,
        },
        legend:false,
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            width: 5,
            dashArray: 0,      
        },
        dataLabels:{
            style: {
                fontSize: '16px',
                fontFamily: 'primary-font-500',
                colors:['#ffffffad'],
                height: 50
                
            },
            dropShadow:false
        },
        plotOptions: {
            pie: {
              donut: {
                size: '40%',
              }
            }
          },
         
        colors:['#14AA72', '#FF7575', '#F6C716','#62EACB'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 100
            },
            legend: {
              position: 'bottom'
            }
          },
          
        }]
      })

      const [series, setSeries] = useState([25, 25, 12.5, 37.5])


  return (
<div id="chart">
  <ReactApexChart options={options} series={series} type="donut" width={450}  />
</div>
  )
}

export default Piechart