import React from 'react'

function AddComments() {
  return (
    <>
                <div className="add__comment">
                    <h2>المشاركة في المناقشة</h2>
                    <form>
                       <input className="form-control" type="text" placeholder="يكتب شيئا"/>
                       <div className="submit">
                        <button type='submit'><img src="./assets/img/news/send.svg" alt="" /></button>
                        <button type='submit'><img src="./assets/img/news/smile.svg" alt="" /></button>
                       </div>
                    </form>
                </div>
    </>
  )
}

export default AddComments