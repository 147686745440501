import React from 'react'
import { Dropdown, DropdownButton, ProgressBar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'

function NewsLists() {
  return (
    <>
    <Header/>
    <section className="tsd__details" style={{backgroundImage:"url(./assets/img/inner-banner.jpg)"}}>
        <div className="container">
            <div className="page__title">
                <div className="icon">
                    <img src="./assets/img/icons/newsLists.svg" alt="" />
                </div>
                <h2>أحدث الأخبار</h2>
            </div>
        </div>
      </section>
<section className="news_poll news__lists">
  <div className="container">
    <div className="row">
      <div className="news__grid p-0">
        <div className="default__heading">
            <DropdownButton id="dropdown-item-button" title="جميع الإدارات">
              <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
              <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
              <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
            </DropdownButton>
        </div>
        <div className="row">
            <div className="col-md-3 col-tab-6">
            <div className="news__card p-0 m-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
            <div className="col-md-3 col-tab-6">
            <div className="news__card p-0 m-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
            <div className="col-md-3 col-tab-6">
            <div className="news__card p-0 m-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
            <div className="col-md-3 col-tab-6">
            <div className="news__card p-0 m-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3 col-tab-6">
            <div className="news__card p-0 m-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
            <div className="col-md-3 col-tab-6">
            <div className="news__card p-0 m-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
            <div className="col-md-3 col-tab-6">
            <div className="news__card p-0 m-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
            <div className="col-md-3 col-tab-6">
            <div className="news__card p-0 m-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3 col-tab-6">
            <div className="news__card p-0 m-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
            <div className="col-md-3 col-tab-6">
            <div className="news__card p-0 m-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
            <div className="col-md-3 col-tab-6">
            <div className="news__card p-0 m-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
            <div className="col-md-3 col-tab-6">
            <div className="news__card p-0 m-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
        </div>
        <div className="text-center"><Link to={'/'} className="btn btn-primary">عرض كافة</Link></div>
      </div>
    </div>
  </div>
</section>
    <Footer/>
    </>
  )
}

export default NewsLists