import React,{useEffect} from 'react'
import './header.css'
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, NavLink } from 'react-router-dom'




function Header() {
    const openSidebar=()=>{
        document.body.classList.add('box-collapse-open')
    }
    const closeSidebar=()=>{
        document.body.classList.remove('box-collapse-open')
    }
  return (
    <React.Fragment>
       <div className='header'>

  {/* ======= Header/Navbar ======= */}
  <nav className="navbar navbar-default navbar-trans navbar-expand-lg fixed-top">
    <div className="container">
      <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarDefault" aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation">
        <span />
        <span />
        <span />
      </button>
      <Link to={'/'} className="logo d-flex align-items-center">
      <img src="assets/img/logo.svg" alt="logo" />
    </Link>
      <div className="navbar-collapse collapse justify-content-center" id="navbarDefault">
        <ul className="navbar-nav">
        <li className="nav-item"><NavLink to={'/'} end>Home</NavLink></li>
        <li className="nav-item"><NavLink to="/newsLists">News</NavLink></li>
        <li className="nav-item"><NavLink to="/events">Events</NavLink></li>
        <li className="nav-item"><NavLink to="/pollLists">Survey</NavLink></li>
        <li className="nav-item"><NavLink to="/announcements">Announcements</NavLink></li>
        <li className="nav-item"><NavLink to="/vaccancy">Career</NavLink></li>
        <li className="nav-item"><NavLink to="/about">About</NavLink></li>
        <li className="nav-item"><NavLink to="/rules">Rules</NavLink></li>
        </ul>
      </div>
      <div className="left__nav" id="navbarDefault">
    <div className="admin__image">
    {/* <img src="assets/img/icons/user.svg" className="notification" alt="" /> */}
    <p>لوي روبن</p>
    </div>
    <div className="notification__icons">
    <Dropdown>
      <Dropdown.Toggle variant="transparent" className='border-0' id="dropdown-basic">
       <img src="assets/img/icons/notification.svg" className="notification" alt="" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="/">رسالة جديدة</Dropdown.Item>
        <Dropdown.Item href="/">تم الإبلاغ عن حادث</Dropdown.Item>
        <Dropdown.Item href="/">رسالة جديدة</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

    </div>


    </div>
    </div>
  </nav>{/* End Header/Navbar */}
</div>

    </React.Fragment>

  )
}

export default Header