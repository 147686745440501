import React from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AddComments from '../components/AddComments'
import Footer from '../components/Footer'
import Header from '../components/Header'

function NewsDetails() {
  return (
    <>
    <Header/>
    <section className="tsd__details" style={{backgroundImage:"url(./assets/img/inner-banner.jpg)"}}>
        <div className="container">
            <div className="page__title">
                <div className="icon">
                    <img src="./assets/img/icons/newsLists.svg" alt="" />
                </div>
                <h2>أحدث الأخبار</h2>
            </div>
        </div>
      </section>
      <section className="news__details">
        <div className="container">
            <div className="row">
            <div className="default__heading justify-content-end mt-5">
            <DropdownButton id="dropdown-item-button" title="جميع الإدارات">
              <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
              <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
              <Dropdown.Item as="button">جميع الإدارات</Dropdown.Item>
            </DropdownButton>
        </div>
            </div>
            <div className="row news__detailsCard">
                <div className="card">
                    <div className="card-body">
                        <div className="news__image">
                            <img src="./assets/img/news/newsDetails.jpg" className='w-100' alt="" />
                        </div>
                        <div className="news__title">
                            <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                            <ul>
                                <li><img src="./assets/img/news/date.svg" alt="" /> 30 مايو 2022 الساعة 1:11 مساءً.</li>
                                <li><img src="./assets/img/news/message.svg" alt="" /> 12 تعليق</li>
                                <li><img src="./assets/img/news/author.svg" alt="" /> مسؤل</li>
                            </ul>
                        </div>
                        <div className="news__description">
                            <p>لوريم إيبسوم هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي للإبسوم العربي وهو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى … لوريم إيبسوم هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي للإبسوم العربي وهو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى … لوريم إيبسوم هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي للإبسوم العربي وهو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى … لوريم إيبسوم هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي للإبسوم العربي وهو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى …</p>
                            <p>لوريم إيبسوم هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي للإبسوم العربي وهو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى … لوريم إيبسوم هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي للإبسوم العربي وهو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى … لوريم إيبسوم هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي للإبسوم العربي وهو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى … لوريم إيبسوم هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي للإبسوم العربي وهو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى …</p>
                            <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">أقرأ المزيد</Link>
                                    </div>
                        </div>

                        <hr />
                        <AddComments/>
                    </div>
                </div>
            </div>

        </div>
      </section>
      <section className="news_poll news__lists pt-0">
  <div className="container">
    <div className="row">
    <div className="default__heading justify-content-start mt-0">
        <h1>المزيد من الأخبار</h1>
    </div>
      <div className="news__grid p-0">
        <div className="row">
            <div className="col-md-3 col-tab-6">
            <div className="news__card m-0 p-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
            <div className="col-md-3 col-tab-6">
            <div className="news__card m-0 p-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
            <div className="col-md-3 col-tab-6">
            <div className="news__card m-0 p-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
            <div className="col-md-3 col-tab-6">
            <div className="news__card m-0 p-0">
                                <div className="card">
                                <div className="news__image">
                                      <img src="assets/img/news/news2.jpg" className="card-img-top" alt="..." />
                                      <div className="date">
                                        <h4>03 Mar</h4>
                                      </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="top__lists">
                                    <div className="grid">
                                        <img src="assets/img/icons/news-admin.svg" alt="" />
                                        <p>مسؤل</p>
                                      </div>
                                      <div className="grid">
                                        <img src="assets/img/icons/news-comment.svg" alt="" />
                                        <p>12 تعليق</p>
                                      </div>
                                    </div>
                                    <div className="news__title">
                                      <h2>شرطة دبي تفاجئ ألف سائح بعروض ترفيهية وتذكارات</h2>
                                    </div>
                                    <div className="text-left">
                                    <Link to={'/'} className="btn btn-primary">اقرأ أكثر</Link>
                                    </div>
                                  </div>
                            </div>
                           </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
    <Footer/>
    </>
  )
}

export default NewsDetails