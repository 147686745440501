import React from 'react'
import { Accordion } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AddComments from '../components/AddComments'
import Footer from '../components/Footer'
import Header from '../components/Header'

function VaccancyLists() {
  return (
    <>
    <Header/>
    <section className="tsd__details" style={{backgroundImage:"url(./assets/img/inner-banner.jpg)"}}>
        <div className="container">
            <div className="page__title">
                <div className="icon">
                    <img src="./assets/img/icons/calendar.svg" alt="" />
                </div>
                <h2>الشواغر المتاحة</h2>
            </div>
        </div>
      </section>

      <section className="faq vaccancy__lists default-bg">
  <div className="container">
  <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>مدير تكنولوجيا المعلومات</Accordion.Header>
        <Accordion.Body>
         <p> لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
         <div className="apply__now">
         <button className="btn-sm btn-primary">تقدم الآن</button>
         </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>الموارد البشرية</Accordion.Header>
        <Accordion.Body>
            <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
            <div className="apply__now">
         <button className="btn-sm btn-primary">تقدم الآن</button>
         </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>أخصائي تدريب</Accordion.Header>
        <Accordion.Body>
            <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
            <div className="apply__now">
         <button className="btn-sm btn-primary">تقدم الآن</button>
         </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
      <Accordion.Header>أخصائي تدريب</Accordion.Header>
        <Accordion.Body>
            <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
            <div className="apply__now">
         <button className="btn-sm btn-primary">تقدم الآن</button>
         </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
      <Accordion.Header>أخصائي تدريب</Accordion.Header>
        <Accordion.Body>
            <p>لوريم إيبسوم العربية هو الشكل العربي للنص اللاتيني الذي يستخدمه المصممون والمطورون عادة كنص وهمي. سيتم استبدال هذا النص بالمحتوى الأصلي</p>
            <div className="apply__now">
         <button className="btn-sm btn-primary">تقدم الآن</button>
         </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </div>
</section>
      <section className="bg-whte">
    <div className="container">
    <AddComments/>
    </div>
</section>
    <Footer/>
    </>
  )
}

export default VaccancyLists