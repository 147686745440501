import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'

function Leaderboard() {
  const [tab, setTab] = useState(false)
  const leaderboard12 = ()=>{
    setTab(false)
  }
  const leaderboard100 = ()=>{
    setTab(true)
  }
  return (
    <>
        <Header/>
    <section className="tsd__details" style={{backgroundImage:"url(./assets/img/inner-banner.jpg)"}}>
        <div className="container">
            <div className="page__title">
                <div className="icon">
                    <img src="./assets/img/icons/leaderboard.svg" alt="" />
                </div>
                <h2>الشواغر المتاحة</h2>
            </div>
        </div>
      </section>
      <section className="leaderboard__lists">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
          <div className="leaderboard">
        <div className="default__heading">
          <div className="leaderboard__btns">
          <button className={tab==false ? 'btn btn-primary' : 'btn btn-outline'} onClick={leaderboard12}>أفضل 12</button>
            <button className={tab==true ? 'btn btn-primary' : 'btn btn-outline'} onClick={leaderboard100}>أفضل 100</button>
          </div>
        </div>
        {
          tab==false ? 
          <div className="top12">
          <div className="col-md-12" >
              <div className="board__grid active">
                <div className="right__content">
                <div className="number"><p>01</p></div>
                  <div className="user">
                    <h4>John Doe</h4>
                    <span>Gold</span>
                  </div>
                </div>
                <div className="rank">
                  <img src="./assets/img/icons/leaderboard-white.svg" alt="" />
                  <h4>رتبتك</h4>
                </div>
                <div className="medal">
                    <img src="assets/img/icons/gold.svg" alt="" />
                  </div>
              </div>
            </div>
            <div className="col-md-12" >
              <div className="board__grid">
                <div className="right__content">
                <div className="number"><p>02</p></div>
                  <div className="user">
                    <h4>John Doe</h4>
                    <span>Silver</span>
                  </div>
                  
                </div>
                <div className="medal">
                    <img src="assets/img/icons/silver.svg" alt="" />
                  </div>
              </div>
            </div>
            <div className="col-md-12" >
              <div className="board__grid">
                <div className="right__content">
                <div className="number"><p>03</p></div>
                  <div className="user">
                    <h4>Andrews</h4>
                    <span>Silver</span>
                  </div>
                  
                </div>
                <div className="medal">
                    <img src="assets/img/icons/silver.svg" alt="" />
                  </div>
              </div>
            </div>
            <div className="col-md-12" >
              <div className="board__grid">
                <div className="right__content">
                <div className="number"><p>04</p></div>
                  <div className="user">
                    <h4>Stefen</h4>
                    <span>Silver</span>
                  </div>
                  
                </div>
                <div className="medal">
                    <img src="assets/img/icons/light-gold.svg" alt="" />
                  </div>
              </div>
            </div>
            <div className="col-md-12" >
              <div className="board__grid">
                <div className="right__content">
                <div className="number"><p>05</p></div>
                  <div className="user">
                    <h4>Jake Doe</h4>
                    <span>Silver</span>
                  </div>
                  
                </div>
                <div className="medal">
                    <img src="assets/img/icons/light-gold.svg" alt="" />
                  </div>
              </div>
            </div>
            <div className="col-md-12" >
              <div className="board__grid">
                <div className="right__content">
                <div className="number"><p>06</p></div>
                  <div className="user">
                    <h4>Jake Doe</h4>
                    <span>Silver</span>
                  </div>
                  
                </div>
                <div className="medal">
                    <img src="assets/img/icons/light-gold.svg" alt="" />
                  </div>
              </div>
            </div>
          </div> :
                  <div className="top100">
                  <div className="col-md-12" >
                      <div className="board__grid active">
                        <div className="right__content">
                        <div className="number"><p>01</p></div>
                          <div className="user">
                            <h4>John Doe</h4>
                            <span>Gold</span>
                          </div>
                          
                        </div>
                        <div className="medal">
                            <img src="assets/img/icons/gold.svg" alt="" />
                          </div>
                      </div>
                    </div>
                    <div className="col-md-12" >
                      <div className="board__grid">
                        <div className="right__content">
                        <div className="number"><p>02</p></div>
                          <div className="user">
                            <h4>John Doe</h4>
                            <span>Silver</span>
                          </div>
                          
                        </div>
                        <div className="medal">
                            <img src="assets/img/icons/silver.svg" alt="" />
                          </div>
                      </div>
                    </div>
                    <div className="col-md-12" >
                      <div className="board__grid">
                        <div className="right__content">
                        <div className="number"><p>03</p></div>
                          <div className="user">
                            <h4>Andrews</h4>
                            <span>Silver</span>
                          </div>
                          
                        </div>
                        <div className="medal">
                            <img src="assets/img/icons/silver.svg" alt="" />
                          </div>
                      </div>
                    </div>
                    <div className="col-md-12" >
                      <div className="board__grid">
                        <div className="right__content">
                        <div className="number"><p>04</p></div>
                          <div className="user">
                            <h4>Stefen</h4>
                            <span>Silver</span>
                          </div>
                          
                        </div>
                        <div className="medal">
                            <img src="assets/img/icons/light-gold.svg" alt="" />
                          </div>
                      </div>
                    </div>
                    <div className="col-md-12" >
                      <div className="board__grid">
                        <div className="right__content">
                        <div className="number"><p>05</p></div>
                          <div className="user">
                            <h4>Jake Doe</h4>
                            <span>Silver</span>
                          </div>
                          
                        </div>
                        <div className="medal">
                            <img src="assets/img/icons/light-gold.svg" alt="" />
                          </div>
                      </div>
                    </div>
                    <div className="col-md-12" >
                      <div className="board__grid">
                        <div className="right__content">
                        <div className="number"><p>06</p></div>
                          <div className="user">
                            <h4>Jake Doe</h4>
                            <span>Silver</span>
                          </div>
                          
                        </div>
                        <div className="medal">
                            <img src="assets/img/icons/light-gold.svg" alt="" />
                          </div>
                      </div>
                    </div>
                  </div>
        }

        </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
    
    </>

  )
}

export default Leaderboard